import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import queryString from 'query-string';
import actions from 'reducers/Editor/actions';
import DateFormat from 'components/ui-components/DateFormat';
import types from 'reducers/Auth/types';
import LanguageSelector from 'components/editor-base/LanguageSelector';
import EditorData from 'components/editor-data/EditorData';
import { KLMOriginSwitch } from 'components/customers/KLM/KLMOriginSwitch';
import EmailEditorTopbar from 'components/channels/email/EmailEditor/components/topbar';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import Button from 'components/ui-components-v2/Button';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import ViewSwitch from 'components/ui-components/ViewSwitch';
import store from '../../store';
import CreativeBuilderPreview from './components/creative-builder-preview';
import MultiEmailPreview from './components/multi-email-preview';
import EmailPreview from './components/email-preview';
import DisplayPreview from './components/display-preview';

import './styles/main.scss';

/**
 * Preview
 * This view displays a preview of campaign materials.
 */
class Preview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: get(props, 'match.params.type'),
            abTesting: false,
            abTestGroupsShown: false,
            isSocial: false,
            displayType: 'list',
            activeTitle: ''
        };
    }

    componentDidMount = () => {
        const { campaignId, token } = this.props.match.params;

        // Save token
        store.dispatch({
            type: types.PREVIEW_LOGIN_SUCCESS,
            payload: {
                previewToken: token
            }
        });

        // Language parsed
        const parsed = queryString.parse(window.location.search);
        let language;
        if (parsed.language) {
            language = parsed.language;
        }

        // Load data via redux
        this.props.loadDataFromPreview(campaignId, token, language);
    };

    componentDidUpdate = (prevProps) => {
        const { type } = this.state;
        const prevLoading = get(prevProps, 'editor.loading', true);
        const curLoading = get(this, 'props.editor.loading', true);

        // Load helper class in case domain is set
        if (get(this, 'props.editor.domain', false)) {
            CustomerHelperLoader.load(this.props.editor.domain);
        }

        // We are not loading anymore
        if (prevLoading && !curLoading) {
            if (type === 'email') {
                // Set the AB testing groups for email.
                const settingsModel = get(this, 'props.editor.previewSetup.settingsModel', 'email.settings');
                this.setAbTesting(settingsModel);
            }

            if (type === 'creativeBuilder') {
                const value = EditorData.getValueFromModel(this.props.editor.previewSetup.model);
                this.setState({
                    isSocial: CreativeBuilderHelpers.getIsSocial(value)
                });
            }
        }
    };

    static mapStateToProps(state) {
        return {
            editor: state.editor
        };
    }

    setAbTesting = (settingsModel) => {
        const abTesting = get(this, `props.editor.data.${settingsModel}.abTesting.test`, false);
        if (abTesting && abTesting !== 'none') {
            this.setState({
                abTesting: abTesting ? [...abTesting] : abTesting,
                abTestGroupsShown: abTesting ? [...abTesting] : false
            });
        } else {
            this.setState({
                abTesting: false,
                abTestGroupsShown: false
            });
        }
    };

    setMultiAbTesting = (activeEmailId) => {
        const dataModel = get(this, 'props.editor.previewSetup.dataModel');
        const settingsModel = `${dataModel}.${activeEmailId}.settings`;
        this.setAbTesting(settingsModel);
    };

    setDisplayType = (displayType) => {
        this.setState({ displayType });
    };

    gotoLink = () => {
        const { editor } = this.props;

        if (process.env.ENV.includes('acceptance')) {
            window.location.href = 'https://' + editor.domain + '.acceptance.campaigndesigner.io/editor/' + editor.id;
        } else {
            window.location.href = 'https://' + editor.domain + '.campaigndesigner.io/editor/' + editor.id;
        }
    };

    render() {
        const { editor } = this.props;
        const { loading, previewSetup } = editor;
        const { abTesting, abTestGroupsShown, isSocial, type, displayType, activeTitle } = this.state;

        return (
            <div>
                {loading && <CircularProgress />}

                {!loading && (
                    <div className="preview-page">
                        <div className="preview-page__title-bar">
                            <div className="preview-page__title-bar__image" style={{ backgroundImage: "url('" + editor.image + "')" }}></div>
                            <div className="preview-page__context">
                                <div className="preview-page__title-bar__title">{editor.data && editor.data.settings && editor.data.settings.title}</div>
                                <div className="preview-page__title-bar__updated">
                                    <DateFormat date={editor.updated && editor.updated.date} type="dateTime" /> -{' '}
                                    {editor && editor.updated && editor.updated.user.name}
                                </div>
                            </div>
                            {editor.domain && process.env.CUSTOMER !== 'klm' && (
                                <div className="preview-page__clickout">
                                    <Button onClick={this.gotoLink} variant="contained" color="primary">
                                        Edit campaign
                                    </Button>
                                </div>
                            )}
                        </div>
                        {type === 'email' || type === 'multi-email' ? (
                            <EmailEditorTopbar
                                abTesting={abTesting}
                                abTestGroupsShown={abTestGroupsShown}
                                value={EditorData.getValueFromModel(editor.previewSetup.model)}
                                originSelector={EditorData.getValueFromModel('settings.origins')}
                                onSetAbTestGroupsShown={(value) => this.setState({ abTestGroupsShown: value })}
                            />
                        ) : (
                            <div className="preview-page__controls">
                                <div className="preview-page__preview-title">
                                    {type === 'creativeBuilder' && displayType === 'list' && <React.Fragment>{activeTitle}</React.Fragment>}
                                </div>
                                <div className="preview-page__controls__right">
                                    {previewSetup && previewSetup.multiLanguage && (
                                        <div>
                                            <LanguageSelector />
                                        </div>
                                    )}

                                    {EditorData.getValueFromModel('settings.origins') && (
                                        <div className="preview-page__origins">
                                            <KLMOriginSwitch />
                                        </div>
                                    )}

                                    {isSocial && <ViewSwitch displayType={displayType} onSetDisplayType={this.setDisplayType} />}
                                </div>
                            </div>
                        )}

                        {type === 'multi-email' ? (
                            <div className="preview-page__multi-email">
                                <MultiEmailPreview
                                    abTesting={abTesting}
                                    abTestGroupsShown={abTestGroupsShown}
                                    onSetActiveEmailId={(activeEmailId) => this.setMultiAbTesting(activeEmailId)}
                                />
                            </div>
                        ) : (
                            <div
                                className={classNames('preview-page__content', {
                                    'preview-page__content--full-width': ['creativeBuilder', 'display'].includes(type)
                                })}>
                                {type === 'creativeBuilder' && (
                                    <CreativeBuilderPreview
                                        displayType={displayType}
                                        isSocial={isSocial}
                                        onSetActiveTitle={(activeTitle) => {
                                            this.setState({ activeTitle });
                                        }}
                                    />
                                )}
                                {type === 'display' && <DisplayPreview />}
                                {type === 'email' && (
                                    <EmailPreview key={'email_' + EditorData.get('origin')} abTesting={abTesting} abTestGroupsShown={abTestGroupsShown} />
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(connect(Preview.mapStateToProps, actions)(Preview));
