import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPreviewCleanDynamicData } from 'components/channels/display/DisplayPreview';

class DisplayPreview extends React.Component {
    static mapStateToProps(state) {
        return {
            editor: state.editor
        };
    }

    render() {
        const { editor } = this.props;
        const previewSetup = editor.previewSetup;
        previewSetup.fullWindow = true;

        return (
            <div className="preview-page__display">
                <DisplayPreviewCleanDynamicData data={previewSetup} language={editor.language} />
            </div>
        );
    }
}

export default withRouter(connect(DisplayPreview.mapStateToProps, {})(DisplayPreview));
