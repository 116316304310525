import React from 'react';
import { TooltipProps } from '@mui/material/Tooltip';
import classNames from 'classnames';
import Tooltip from './tooltip';

import '../styles/tooltip-card.scss';

/**
 * TooltipCard component with specific card styling.
 */
const TooltipCard: React.FC<TooltipProps> = (props) => {
    return (
        <Tooltip {...props} classes={{ ...props.classes, tooltip: classNames('tooltip-card', props.classes?.tooltip) }}>
            {props.children}
        </Tooltip>
    );
};

export default TooltipCard;
