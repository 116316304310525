import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EmailPreviewComponent from 'components/channels/email/EmailPreview';

class EmailPreview extends React.Component {
    static mapStateToProps(state) {
        return {
            editor: state.editor
        };
    }

    render() {
        const { editor, abTesting, abTestGroupsShown } = this.props;
        const previewSetup = editor.previewSetup;
        previewSetup.fullWindow = true;

        return (
            <div className="preview-page__email">
                <EmailPreviewComponent data={previewSetup} language={editor.language} additionalProps={{ abTestGroupsShown, abTesting }} />
            </div>
        );
    }
}

export default withRouter(connect(EmailPreview.mapStateToProps, {})(EmailPreview));
