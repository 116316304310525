import React from 'react';
import { useHistory } from 'react-router-dom';
import { withComponentStore } from 'components/data/ComponentStore';
import FeedManagerDataSetForm from 'components/feed-management/DataSetForm';

const Settings = ({ dataSet, inCampaign, onSetTab }) => {
    const history = useHistory();
    const handleOnClose = () => {
        if (!inCampaign) {
            history.push(`/feed-management/dataset/${dataSet._id}`);
        } else {
            onSetTab('feed');
        }
    };

    return (
        <div className="feed-management-settings">
            <FeedManagerDataSetForm dataSet={dataSet} onClose={handleOnClose} />
        </div>
    );
};

export default withComponentStore(Settings, 'FeedManager', []);
