import React from 'react';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';

/**
 * Custom tooltip component.
 **/
const Tooltip: React.FC<TooltipProps> = (props) => {
    const { children, ...other } = props;

    return (
        <MuiTooltip placement="bottom" {...other}>
            {children}
        </MuiTooltip>
    );
};

export default Tooltip;
