import Translation from 'components/data/Translation';

const getTemplateTypes = [
    {
        id: 'dynamicVideo',
        title: Translation.get('template.types.dynamicVideo', 'common'),
        icon: 'smart_display',
        description: Translation.get('template.types.descriptions.dynamicVideo', 'common')
    },
    {
        id: 'dynamicImage',
        title: Translation.get('template.types.dynamicImage', 'common'),
        icon: 'image',
        description: Translation.get('template.types.descriptions.dynamicImage', 'common')
    },
    {
        id: 'displayAd',
        title: Translation.get('template.types.displayAd', 'common'),
        icon: 'web',
        description: Translation.get('template.types.descriptions.displayAd', 'common')
    },
    {
        id: 'dynamicPDF',
        title: Translation.get('template.types.dynamicPDF', 'common'),
        icon: 'picture_as_pdf',
        description: Translation.get('template.types.descriptions.dynamicPDF', 'common')
    },
    {
        id: 'dynamicImageDesigned',
        title: Translation.get('template.types.dynamicImageDesigned', 'common'),
        icon: 'image',
        description: Translation.get('template.types.descriptions.dynamicImageDesigned', 'common')
    },
    {
        id: 'displayAdDesigned',
        title: Translation.get('template.types.displayAdDesigned', 'common'),
        icon: 'web',
        description: Translation.get('template.types.descriptions.displayAdDesigned', 'common')
    },
    {
        id: 'dynamicVideoDesigned',
        title: Translation.get('template.types.dynamicVideoDesigned', 'common'),
        icon: 'smart_display',
        description: Translation.get('template.types.descriptions.dynamicVideoDesigned', 'common')
    },
    {
        id: 'dynamicPDFDesigned',
        title: Translation.get('template.types.dynamicPDFDesigned', 'common'),
        icon: 'brush',
        description: Translation.get('template.types.descriptions.dynamicPDFDesigned', 'common')
    },
    {
        id: 'dynamicAfterEffects',
        title: Translation.get('template.types.dynamicAfterEffects', 'common'),
        icon: 'movie',
        description: Translation.get('template.types.descriptions.dynamicAfterEffects', 'common')
    },
    {
        id: 'dynamicInDesign',
        title: Translation.get('template.types.dynamicInDesign', 'common'),
        icon: 'picture_as_pdf',
        description: Translation.get('template.types.descriptions.dynamicInDesign', 'common')
    },
    {
        id: 'socialChannelItemOverwrites',
        title: Translation.get('template.types.socialChannelItemOverwrites', 'common'),
        icon: 'quickreply',
        description: Translation.get('template.types.descriptions.socialChannelItemOverwrites', 'common')
    },
    {
        id: 'assetItemOverwrites',
        title: Translation.get('template.types.assetItemOverwrites', 'common'),
        icon: 'quickreply',
        description: Translation.get('template.types.descriptions.assetItemOverwrites', 'common')
    },
    {
        id: 'marketDefault',
        title: Translation.get('template.types.marketDefault', 'common'),
        icon: 'flag',
        description: Translation.get('template.types.descriptions.marketDefault', 'common')
    },
    {
        id: 'emailBase',
        title: Translation.get('template.types.emailBase', 'common'),
        icon: 'mail',
        description: Translation.get('template.types.descriptions.emailBase', 'common')
    },
    {
        id: 'emailBlock',
        title: Translation.get('template.types.emailBlock', 'common'),
        icon: 'forward_to_inbox',
        description: Translation.get('template.types.descriptions.emailBlock', 'common')
    },
    {
        id: 'landingpageBlock',
        title: Translation.get('template.types.landingpageBlock', 'common'),
        icon: 'wysiwyg',
        description: Translation.get('template.types.descriptions.landingpageBlock', 'common')
    },
    {
        id: 'social',
        title: Translation.get('template.types.social', 'common'),
        icon: '3p',
        description: Translation.get('template.types.descriptions.social', 'common')
    },
    {
        id: 'socialBase',
        title: Translation.get('template.types.socialBase', 'common'),
        icon: '3p',
        description: Translation.get('template.types.descriptions.socialBase', 'common')
    },
    {
        id: 'socialChannelItem',
        title: Translation.get('template.types.socialChannelItem', 'common'),
        icon: '3p',
        description: Translation.get('template.types.descriptions.socialChannelItem', 'common')
    },
    {
        id: 'staticAsset',
        title: Translation.get('template.types.staticAsset', 'common'),
        icon: 'upload_file',
        description: Translation.get('template.types.descriptions.staticAsset', 'common')
    },
    {
        id: 'staticAssetVideo',
        title: Translation.get('template.types.staticAssetVideo', 'common'),
        icon: 'upload_file',
        description: Translation.get('template.types.descriptions.staticAssetVideo', 'common')
    },
    {
        id: 'staticAssetZip',
        title: Translation.get('template.types.staticAssetZip', 'common'),
        icon: 'upload_file',
        description: Translation.get('template.types.descriptions.staticAssetZip', 'common')
    },
    {
        id: 'staticAssetImage',
        title: Translation.get('template.types.staticAssetImage', 'common'),
        icon: 'upload_file',
        description: Translation.get('template.types.descriptions.staticAssetImage', 'common')
    },
    {
        id: 'staticAssetDisplay',
        title: Translation.get('template.types.staticAssetDisplay', 'common'),
        icon: 'upload_file',
        description: Translation.get('template.types.descriptions.staticAssetDisplay', 'common')
    }
];

export default getTemplateTypes;
