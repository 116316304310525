import React from 'react';
import { connect } from 'react-redux';
import { withCreativeTemplates } from 'components/data/Templates';
import CreativeBuilderPreviewComponent from 'components/creative-builder/CreativeBuilderPreview';

/**
 * Creative Builder Preview
 * This displays all the element in the creative builder as a preview.
 */
class CreativeBuilderPreview extends React.Component {
    static mapStateToProps(state) {
        return {
            editor: state.editor
        };
    }

    render() {
        const { editor, displayType, onSetActiveTitle } = this.props;
        const previewSetup = editor.previewSetup;
        previewSetup.fullWindow = true;

        return <CreativeBuilderPreviewComponent data={{ ...previewSetup, displayType, onSetActiveTitle }} language={editor.language} />;
    }
}

export default withCreativeTemplates(connect(CreativeBuilderPreview.mapStateToProps, {})(CreativeBuilderPreview));
