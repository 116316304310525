import React from 'react';
import classNames from 'classnames';
import ButtonBase from '@mui/material/ButtonBase';

import './styles/main.scss';

interface Props {
    children?: JSX.Element;
    disabled?: boolean;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ToolIconButton: React.FC<Props> = ({ children, onClick, disabled, className, ...props }) => {
    return (
        <ButtonBase {...props} onClick={onClick} disabled={disabled} className={classNames('tool-icon-button', className)}>
            {children && children}
        </ButtonBase>
    );
};

export default ToolIconButton;
