import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';

interface ViewSwitchProps {
    onSetDisplayType: (value) => void;
    displayType: string;
}

/**
 * Generic viewswitch
 */
const ViewSwitch: React.FC<ViewSwitchProps> = ({ onSetDisplayType, displayType }) => {
    return (
        <ToggleButtonGroup
            value={displayType}
            exclusive
            onChange={(event, value) => {
                if (!value) return;
                onSetDisplayType(value);
            }}>
            <ToggleButton value="list">
                <Icon>view_list</Icon>
            </ToggleButton>
            <ToggleButton value="grid">
                <Icon>grid_view</Icon>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default ViewSwitch;
