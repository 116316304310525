import React from 'react';
import classNames from 'classnames';

import '../styles/tooltip-details.scss';

interface Classes {
    root?: string;
    header?: string;
    footer?: string;
    image?: string;
    title?: string;
    description?: string;
}

export interface TooltipDetailsProps {
    imageSrc: string;
    title: string;
    description: string;
    classes?: Classes;
}

/**
 * TooltipDetails component displays the details of a tooltip.
 */
const TooltipDetails: React.FC<TooltipDetailsProps> = ({ imageSrc, title, description, classes }) => {
    return (
        <div className={classes?.root}>
            <div className={classNames('tooltip-details__header', classes?.header)}>
                <img className={classes?.image} src={imageSrc} />
            </div>
            <div className={classNames('tooltip-details__footer', classes?.footer)}>
                <h4 className={classes?.title}>{title}</h4>
                <p className={classes?.description}>{description}</p>
            </div>
        </div>
    );
};

export default TooltipDetails;
